<template>
  <div>
       
    <b-card class="mb-2">
      <b-card-text v-if="ContratData">
        <b-row>
          <b-col md="12" sm="12">
            <div class="d-flex bd-highlight mb-2">
                <div class="bd-highlight text-uppercase h3 font-weight-bold" v-if="ContratData.risque">
                <b>{{ ContratData.risque.label }} </b> <b v-if="ContratData.assureur">- {{ ContratData.assureur.nom_com_assureur }}</b>
                </div>
              <div class="ml-auto bd-highlight">
                <div class="demo-inline-spacing">
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-0"
                    v-if="ContratData.id && ContratData.source === 'EXTERNE'"
                    @click="$emit('update-contrat-externe', ContratData, true)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Modifier</span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-0"
                    v-if="ContratData.id && ContratData.source === 'EXTERNE'"
                    @click="$emit('supprimer-contrat', ContratData.id,ContratData.vgt_id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span class="align-middle text-capitalize">Supprimer</span>
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12">
            <b-row class="mb-4">
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Date d'effet : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        formattingDate($_.get(
                          ContratData,
                          "date_effet",
                          ""
                        ))
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Echeance anniversaire : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        ($_.get(
                          ContratData,
                          "echeance_anniversaire",
                          ""
                        ))
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Type de paiement : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        formatTypePaiement(($_.get(
                          ContratData,
                          "fractionnement",
                          ""
                        )))
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Modalité de paiement: </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        ($_.get(
                          ContratData,
                          "mode_paiement",
                          ""
                        ))
                      }}
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Prime TTC : 
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        $_.get(
                          ContratData,
                          "prime",
                          ""
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Contrat à la concurrence : 
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ ContratData.concurrence ? 'Oui' : 'Non' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Courtier : 
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        $_.get(
                          ContratData,
                          "user.personne_physique.prenom",
                          ""
                        )
                      }}
                      {{
                        $_.get(
                          ContratData,
                          "user.personne_physique.nom",
                          ""
                        )
                      }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            
            <b-row v-if="ContratData.id && ContratData.source != 'EXTERNE'">
                <b-col cols="12" xl="12">
                    <h3 class="text-primary text-decoration-underline font-weight-bolder mb-1"><u>Détails</u></h3>
                </b-col>
                <b-col cols="12" xl="6">
                <table v-if="ContratData.risque.label == '2-Roues'" class="mt-2 mt-xl-0 w-100">

                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                            >Souscripteur : </span
                          >
                    </th>
                    <td>
                      <a style="text-decoration: underline;" @click="$emit('show-prospect', ContratData.contrable.id, ContratData.contrable.categorie, ContratData.contrable.type)">{{ $_.get(ContratData.contrable, "civilite", "") }} {{ $_.get(ContratData.contrable, "nom", "") }} {{ $_.get(ContratData.contrable, "prenom", "") }}</a>
                    </td>
                  </tr>
                  <tr>
                            <th class="pb-50">
                              <span class="font-weight-bolder"
                                >Conducteur : </span
                              >
                            </th>
                            <td class="pb-50 text-capitalize" v-if="ContratData.devis.devis_objets[0].objet.objeable.s_is_conducteur == 1"> 
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', ContratData.devis.devisable.id, ContratData.devis.devisable.categorie, ContratData.devis.devisable.type)">
                                {{( ContratData.devis.devisable.civilite ?$_.get(ContratData.devis.devisable, "civilite", "").toLowerCase() :'') + ' ' + $_.get(ContratData.devis.devisable, "nom", "") + ' '+ $_.get(ContratData.devis.devisable, "prenom", "") }}
                                </a>
                            </td>
                            <td class="pb-50 text-capitalize" v-if="ContratData.devis.devis_objets[0].objet.objeable.s_is_conducteur == 0 && ContratData.devis.devis_objets[0].objet.objeable.conjoint == 1 && ContratData.devis.devis_objets[0].objet.objeable.lien === 'CONJOINT'">
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', ContratData.devis.devisable.lien[0].prospeable.id, ContratData.devis.devisable.lien[0].prospeable.categorie, ContratData.devis.devisable.lien[0].prospeable.type)">{{(ContratData.devis.devisable.lien[0].prospeable.civilite ? $_.get(ContratData.devis.devisable.lien[0].prospeable, "civilite", "").toLowerCase() :'') + ' ' + $_.get(ContratData.devis.devisable.lien[0].prospeable, "nom", "") + " "+ $_.get(ContratData.devis.devisable.lien[0].prospeable, "prenom", "") }}</a>
                            </td> 
                            <td class="pb-50 text-capitalize" v-if="ContratData.devis.devis_objets[0].objet.objeable.s_is_conducteur == 0 && ContratData.devis.devis_objets[0].objet.objeable.conjoint == 0 && ContratData.devis.devis_objets[0].objet.objeable.lien === 'ENFANT'">
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', ContratData.devis.devisenfants[0].personnephysique.id,ContratData.devis.devisenfants[0].personnephysique.categorie,ContratData.devis.devisenfants[0].personnephysique.type)">
                                {{ (ContratData.devis.devisenfants[0].personnephysique.civilite ?  $_.get(ContratData.devis.devisenfants[0].personnephysique, "civilite", "").toLowerCase() : '')  + ' ' + $_.get(ContratData.devis.devisenfants[0].personnephysique, "nom", "") + " "+ $_.get(ContratData.devis.devisenfants[0].personnephysique, "prenom", "") }}
                              </a>
                            </td> 
                            <td class="pb-50 text-capitalize" v-if="ContratData.devis.devis_objets[0].objet.objeable.s_is_conducteur == 0 && ContratData.devis.devis_objets[0].objet.objeable.conjoint == 0 && ContratData.devis.devis_objets[0].objet.objeable.lien === 'PARENT'">
                              <a style="text-decoration: underline;" @click="$emit('show-prospect', ContratData.devis.devisenfants[0].personnephysique.id,ContratData.devis.devisenfants[0].personnephysique.categorie,ContratData.devis.devisenfants[0].personnephysique.type)">
                                {{ (ContratData.devis.devisenfants[0].personnephysique.civilite ?  $_.get(ContratData.devis.devisenfants[0].personnephysique, "civilite", "").toLowerCase() : '')  + ' ' + $_.get(ContratData.devis.devisenfants[0].personnephysique, "nom", "") + " "+ $_.get(ContratData.devis.devisenfants[0].personnephysique, "prenom", "") }}
                              </a>
                            </td> 
                          </tr> 
                <tr>
                    <th>
                       <span class="font-weight-bolder"
                                >Date de permis : </span
                      >
                     </th>
                     <td class="pb-50 text-capitalize"   v-for="permi in ContratData.devis.devis_permis" :key="permi.id">
                      <span >{{permi.permis.label +' : '+ changeFormatDateShort(permi.permis.date) }}</span>          
                    </td>
                  </tr>  
                  <tr>
                        <th class="pb-50">
                          <span class="font-weight-bolder"
                            >Véhicule : </span
                          >
                        </th>
                        <td class="pb-50 text-capitalize">
                          <span v-if="ContratData.devis.devis_vehicule.vehicule"> {{ (ContratData.devis.devis_vehicule.vehicule.marque) + ' ' + ContratData.devis.devis_vehicule.vehicule.modele + ' ' + ContratData.devis.devis_vehicule.vehicule.cylindree}}</span>
                        </td>
                      </tr>  
                </table>

                <table class="mt-2 mt-xl-0 w-100" v-else>
                  <tr v-for="(entreprise) in ContratData.devis.devis_entreprises" :key="entreprise.id">
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Entreprise : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                        <a style="text-decoration: underline;" @click="$emit('show-prospect', entreprise.personnemorale.id, entreprise.personnemorale.categorie, entreprise.personnemorale.type)">{{ $_.get(entreprise.personnemorale, "denomination_commercial", "") }}</a>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Assuré principal : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      <a style="text-decoration: underline;" @click="$emit('show-prospect', ContratData.contrable.id, ContratData.contrable.categorie, ContratData.contrable.type)">
                        {{ $_.get(ContratData.contrable, "civilite", "") + ' ' + $_.get(ContratData.contrable, "nom", "") + " "+ $_.get(ContratData.contrable, "prenom", "") }}
                      </a>
                    </td>
                  </tr>
                  <tr v-if="$_.get(ContratData.devis.devis_objets[0].objet.objeable, 'conjoint', '') == 1">
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Conjoint : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                        <a style="text-decoration: underline;"
                        @click="$emit('show-prospect', ContratData.devis.devisable.lien[0].prospeable.id, ContratData.devis.devisable.lien[0].prospeable.categorie, ContratData.devis.devisable.lien[0].prospeable.type)">{{
                          $_.get(ContratData.devis.devisable.lien[0].prospeable, "civilite", "") + ' ' + $_.get(ContratData.devis.devisable.lien[0].prospeable, "nom", "") + " "+ $_.get(ContratData.devis.devisable.lien[0].prospeable, "prenom", "")
                        }}</a>
                    </td>
                  </tr>
                  <tr v-for="(enfant, index) in ContratData.devis.devisenfants.slice(0, 3)" :key="enfant.id">
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Enfant {{ index + 1 }} : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      <a style="text-decoration: underline;"
                      @click="$emit('show-prospect', enfant.personnephysique.id, enfant.personnephysique.categorie, enfant.personnephysique.type)">
                      {{ $_.get(enfant.personnephysique, "civilite", "") + ' ' + $_.get(enfant.personnephysique, "nom", "") + " "+ $_.get(enfant.personnephysique, "prenom", "") }}
                      </a>
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100" v-if="ContratData.devis.devisenfants.length > 3">
                  <tr v-for="(enfant, index) in ContratData.devis.devisenfants.slice(3, ContratData.devis.devisenfants.length)" :key="enfant.id">
                    <th class="pb-50">
                      <span class="font-weight-bolder"
                        >Enfant {{ index + 4 }} : </span
                      >
                    </th>
                    <td class="pb-50 text-capitalize">
                      <a style="text-decoration: underline;"
                      @click="$emit('show-prospect', enfant.personnephysique.id, enfant.personnephysique.categorie, enfant.personnephysique.type)">
                      {{ $_.get(enfant.personnephysique, "civilite", "") + ' ' + $_.get(enfant.personnephysique, "nom", "") + " "+ $_.get(enfant.personnephysique, "prenom", "") }}
                      </a>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
             <b-row v-else>
                <b-col cols="12" xl="12">
                    <h3 class="text-primary text-decoration-underline font-weight-bolder mb-1"><u>Détails</u></h3>
                    <b-col cols="12" xl="6">
                      <table class="mt-2 mt-xl-0 w-100">
                        <tr>
                          <th class="pb-50">
                            <span class="font-weight-bolder"
                              >Souscripteur : </span
                            >
                          </th>
                          <td class="pb-50 text-capitalize" v-if="ContratData.contrable.type == 'PERSONNE_PHYSIQUE'">
                            <a style="text-decoration: underline;" @click="$emit('show-prospect', ContratData.contrable.id, ContratData.contrable.categorie, ContratData.contrable.type)">{{ $_.get(ContratData.contrable, "civilite", "") }} {{ $_.get(ContratData.contrable, "nom", "") }} {{ $_.get(ContratData.contrable, "prenom", "") }}</a>
                          </td>
                          <td class="pb-50 text-capitalize" v-else>
                            <a style="text-decoration: underline;" @click="$emit('show-prospect', ContratData.contrable.id, ContratData.contrable.categorie, ContratData.contrable.type)">{{ $_.get(ContratData.contrable, "denomination_commercial", "") }}</a>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                </b-col>
              </b-row>
              <b-row class="mt-2" v-if="ContratData.id && ContratData.source == 'EXTERNE'">
                <b-col cols="12" xl="12">
                    <span class="text-primary text-decoration-underline font-weight-bolder mb-1 h3"><u>Commissions</u></span> <b-badge v-if="ContratData.previsionnel" variant="light-info">{{ 'prévisionnel' }}</b-badge>
                    <b-col cols="12" xl="6">
                      <table class="mt-2 mt-xl-0 w-100">
                        <tr>
                          <th class="pb-50">
                            <span class="font-weight-bolder">Taux de commission : </span>
                          </th>
                          <td class="pb-50 text-capitalize">
                            {{ ContratData.taux ? ContratData.taux+'%' : '' }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-50">
                            <span class="font-weight-bolder">Frais de courtage : </span>
                          </th>
                          <td class="pb-50 text-capitalize">
                            {{ numberFormatCurrency(ContratData.frais_courtage) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-50">
                            <span class="font-weight-bolder">Commission récurrente : </span>
                          </th>
                          <td class="pb-50 text-capitalize">
                            {{ numberFormatCurrency(ContratData.commission_recurrente) }}
                          </td>
                        </tr>
                        <tr v-if="ContratData.fractionnement == 'PRIME_UNIQUE' || ContratData.fractionnement == 'VERSEMENTS_LIBRES'">
                          <th class="pb-50">
                            <span class="font-weight-bolder">Commission unique - précompte : </span>
                          </th>
                          <td class="pb-50 text-capitalize">
                            {{ numberFormatCurrency(ContratData.commission_unique_precompte) }}
                          </td>
                        </tr>
                      </table>
                    </b-col>
                </b-col>
            </b-row>
            <b-row class="mt-1 mb-1" v-if="ContratData.risque.label == '2-Roues' && ContratData.document_manquant" >
              <b-col cols="12">
                  <h3 class="text-primary text-decoration-underline font-weight-bolder mb-1"><u>Pièces justificatives</u></h3>
                  <div class="row">
                    <div class="col-xl-6"  v-for="document_manquant of ContratData.document_manquant" :key="document_manquant.id">
                       <table class="mt-2 mt-xl-0 w-100">
                          <tr>
                            <td class="pb-50 w-50" v-if="document_manquant.statut == 'ROUGE'"  @click = "showSidebarPieceJustification(document_manquant.id, document_manquant.libele)" >
                              <a style="text-decoration: underline;"> <span v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="document_manquant.info_bul">{{ document_manquant.libele }}</span> </a>
                            </td>
                            <td class="pb-50 w-50" v-else >
                              <span v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="document_manquant.info_bul">{{ document_manquant.libele }}</span>
                            </td>
                            <td class="pb-50" v-if="document_manquant.statut == 'VERT'" >
                              <span v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="'Validé'"  class="vert-dot"></span>
                            </td>
                            <td class="pb-50" v-else-if ="document_manquant.statut == 'ROUGE'" >
                              <span v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="'A envoyer'"  class="red-dot"></span>
                            </td>
                            <td class="pb-50" v-else >
                              <span v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="'Envoyé, en attente de validation'"  class="orange-dot"></span>
                            </td>
                          </tr>
                        </table>
                    </div>
                  </div> 
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row >
          <b-col cols="12" xl="8">
            <h3 class="text-primary text-decoration-underline font-weight-bolder mb-1"><u>Origine</u></h3>
              <table class="mt-2 mt-xl-0 w-100">

                <tr>
                  <th class="pb-50">
                    <span class="font-weight-bolder"
                      >Crée par : </span
                    >
                  </th>
                  <td class="pb-50 text-capitalize">
                    <p>
                      <template v-if="ContratData.createur_contrat">
                        {{ ContratData.createur_contrat.user.personne_physique.prenom }} {{ ContratData.createur_contrat.user.personne_physique.nom }}
                      </template>
                    </p>
                  </td>
                </tr>

                <tr>
                    <th class="pb-50">
                      <span class="font-weight-bolder">Intermédiaire(s) : </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      
                      <template v-if="ContratData.courtiers_users_by_intermediares && ContratData.courtiers_users_by_intermediares.length > 0">
                        <template v-for="intermediaire in ContratData.courtiers_users_by_intermediares">
                          {{ intermediaire.user.personne_physique.prenom.replace('_', ' ') }} {{ intermediaire.user.personne_physique.nom.replace('_', ' ') }} <br>
                        </template>
                      </template>
                    </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <span class="font-weight-bolder"
                      >Type : </span
                    >
                  </th>
                  <td class="pb-50 text-capitalize">
                    <template v-if="ContratData.origine"><p>{{ ContratData.origine}}</p></template>
                  </td>
                </tr>

                </table>
            

           
          </b-col>
        </b-row>

      </b-card-text>
    </b-card>
      <b-sidebar id="sidebar-piecejustification" bg-variant="white" sidebar-class="sidebar-lg"   ref="piecejustification" shadow backdrop no-header right>
        <template>
           <b-overlay
            :show="showLoading"
            no-wrap
        />

         <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Envoyer une pièce justificative</h5>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hideSideBare()" />
          </div>

          <validation-observer ref="formPieceJustification">
            <b-form class="p-2" @submit.prevent="addPieceJustification">

              <b-form-group class="mt-2" label-for="h-document">
                <validation-provider #default="{ errors }" name="document" rules="required">
                  <label @click="openToSelectFile" style="cursor: pointer;">{{ document_choix}}*</label>

                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'"  @click="openToSelectFile" >
                        <b-form-file type="file" id="fileDocumentPieceJustification" class="d-none" v-model="file_piece_justification" />
                        <feather-icon icon="UploadCloudIcon" />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input disabled placeholder="Choisissez un document" :value="file_piece_justification ? file_piece_justification.name : ''" :state="errors.length > 0 ? false : null" />
                  </b-input-group>

                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Envoyer
            </b-button>

            
          
            </b-form>
          </validation-observer>
          </template>
        </b-sidebar>
  </div>
</template>

<script>
import {
  BBadge,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BFormSelectOption,
  BButton, 
  BCard, 
  BCardText, 
  BCol, 
  BRow,
  BInputGroup,
  BFormFile,
  BSidebar, 
  VBToggle,
  VBTooltip,
  BInputGroupPrepend,


} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BSidebar,
    BOverlay,
    BButton,
    BFormFile,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormSelect,
    BFormTextarea,
    BFormSelectOption,
    BInputGroup,
    BCard,
    BCardText,
    ValidationObserver,
    BInputGroupPrepend,
    ValidationProvider
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,

  },
  props: {
    ContratData: {
      type: Object,
      default: {
        id: null,
        type: null
      },
      required: true,
    }
  },
  data() {
    return {
      showLoading : false,
      file_piece_justification: null,
      document_id_manquant: null,
      document_choix:null

      
    }
  },
  methods: {
    addPieceJustification() {
      this.$refs.formPieceJustification.validate().then(success => {
        if (success) {
          this.showLoading = true
          const formData = new FormData()
          // Append the rest of your form data
          var id_doc = this.document_id_manquant

          formData.append('id', this.document_id_manquant)
          formData.append('contrat_id', this.ContratData.id)
          formData.append('file', this.file_piece_justification)
        
          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
          this.$http
            .post('/document/fmaEnvoyerElementsManquants', formData, config)
            .then(res => {
              if (res.data.success) {
                this.hideSideBare()
                this.messageToast(res.data.data.message, "Succès", "success");
                for (let i = 0; i < this.ContratData.document_manquant.length; i++) {
                  if (this.ContratData.document_manquant[i].id == id_doc) {
                    this.ContratData.document_manquant[i].statut = 'ORANGE'
                  }
                }
                this.showLoading = false

              }else {
                this.showLoading = false
                this.hideSideBare()
                this.messageToast("Envoyer élément manquant ñ'a pas bien été envoyé", 'Erreur', 'error')
              }
            })
            .catch(err => {
              this.showLoading = false
              this.hideSideBare()
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.error(err)
            })         

        }
      })   
    },
    showSidebarPieceJustification(id, document) {
      this.file_piece_justification = null
      this.$refs.formPieceJustification.reset()
      this.document_id_manquant = id
      this.document_choix = document
      this.$root.$emit('bv::toggle::collapse', 'sidebar-piecejustification')
    },
    hideSideBare() {
      this.file_piece_justification = null
      this.document_id_manquant = null
      this.$refs.formPieceJustification.reset()
      this.$refs.piecejustification.hide()
    },
    openToSelectFile: () => {
      document.getElementById('fileDocumentPieceJustification').click()
    },
    date_expiration(data_effet, date_envoi) {
      if(data_effet && date_envoi) {
        let date_envoi = moment(moment(date_envoi).format('YYYY-MM-DD')).add(14, 'days')
        let date_expiration = moment(moment(data_effet).add(1, 'M')).startOf('month')
        let dt = date_envoi > date_expiration ? date_expiration : date_envoi
        return this.changeFormatDateShortNormal(dt)
      }
      return ''
    },
    goToAnotherFicheClient(prospectId, prospectType, prospectCategorie) {
      this.$emit('go-to-another-fiche-client', prospectId, prospectType, prospectCategorie)
    },
    formattingDate(date) {
      if (date) {
        let date_formatting = date;
        date_formatting = date_formatting.split("-");
        date_formatting =
          date_formatting[2] +
          "/" +
          date_formatting[1] +
          "/" +
          date_formatting[0];
        return date_formatting;
      }
      return null;
    },
    formattingDateAddYear(date) {
      if (date) {
        let date_formatting = moment(moment(date).format('YYYY-MM-DD')).add(1, 'year').format('YYYY-MM-DD')
        return this.formattingDate(date_formatting);
      }
      return null;
    },
    formatTypePaiement(typePaiement){
      switch (typePaiement) {
        case 'ANNUEL':
          return 'Annuel';
        case 'MENSUEL':
          return 'Mensuel';
        case 'PRIME_UNIQUE':
          return 'Prime unique';
        case 'SEMESTRIEL':
          return 'Semestriel';
        case 'TRIMESTRIEL':
          return 'Trimestriel';
        case 'VERSEMENTS_LIBRES':
          return 'Versements libres';
        default:
          return "";
      }
    }
  },
}
</script>

<style>
.vert-dot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.red-dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.orange-dot {
  height: 10px;
  width: 10px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}

</style>
