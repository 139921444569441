<template>
  <div>
    <b-overlay :show="showLoading" no-wrap/>
    <b-row>
      <b-col md="6" sm="12">
        <span class="h1 text-primary font-weight-bolder ml-1 text-uppercase"> 
          Fiche Contrat :
          <span>{{ ContratData.police_no ? ContratData.police_no : ContratData.police_no }}</span>
        </span>
        <b-badge :variant="statutContratVariant(ContratData.statut)">{{ ContratData.statut }}</b-badge>
      </b-col>
      <b-col md="6" sm="12">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="text-uppercase float-md-right"
            size="md"
            variant="primary"
            @click="backToListing"
            id="revenir-liste-projets"
        >
          <feather-icon icon="ArrowLeftIcon"/>
          <span class="align-middle"> revenir à la liste</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12" sm="12">
        <card-information :contrat-data="ContratData"
        @supprimer-contrat="$emit('supprimer-contrat', ContratData.id)"
        @update-contrat-externe="$emit('update-contrat-externe', ContratData, true)"
        @show-prospect="goToAnotherFicheClient" />
      </b-col>
      <b-col md="12" sm="12">
        <b-card class="mb-2">
          <tabs-details-fiche-contrat 
          :contrat-data="ContratData"
          :isDocumentAdded="isDocumentAdded"
          @initial-docs-refreshed="initialDocsRefreshed"
          @ajouter-document="$emit('ajouter-document', ContratData.contrable.id, ContratData.id, ContratData.contrable.type, true)" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCardText, BCol, BOverlay, BRow,VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CardInformation from '@/views/contrats/CardInformation.vue'
import tabsDetailsFicheContrat from '@/views/contrats/TabsDetailsFicheContrat.vue'

export default {
  components: {
    // UI
    // BTV
    BOverlay,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    VBToggle,
    //Ui
    CardInformation,
    tabsDetailsFicheContrat
  },
  directives: { Ripple },
  props: {
    contratChosen: {
      type: Object,
      default: {
        id: null,
        type: null
      },
      required: true,
    },
    isContratUpdated: {
      type: Boolean,
      default: false,
      required: false
    },
    isDocumentAdded: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      showLoading: false,
      ContratData: {}
    }
  },
  watch: {
    contratChosen: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.id && val.type) {
          this.showLoading = true
          this.fetchDataContrat(val.id).then(() => {
            this.showLoading = false
          }).catch(()=>{
            this.showLoading = false
          });
        }
      }
    },
    isContratUpdated: {
      handler(val) {
        if(val) {
          this.showLoading = true
          this.fetchDataContrat(this.ContratData.id).then(() => {
            this.showLoading = false
            this.$emit('updated-iscontrat-data', false)
          }).catch(()=>{
            this.showLoading = false
            this.$emit('updated-iscontrat-data', false)
          });
        }
      }
    }
  },

  methods: {
    backToListing() {
      this.$emit('back-to-listing', 'Listing')
    },
    initialDocsRefreshed(refreshed) {
      this.$emit('initial-docs-refreshed', refreshed)
    },
    async fetchDataContrat(id) {
      await this.$http.get(`/contrat/getContratDetailById/${id}`)
          .then(res => {
            console.log(res.data.data);
            this.ContratData = res.data.data
          })
          .catch(err => {
            this.backToListing()
            console.log(err)
          })
    },
    statutContratVariant(statut) {
      try {
        const statutProjetColor = {
          'Devis envoye': 'light-info',
          'En attente signature': 'light-info',
          'EN_COURS': 'light-warning',
          'Refuse par le client': 'light-danger',
          'Dupliquer': 'light-warning',
          'Expire': 'light-dark',
          'Devis valide': 'light-success'
        }
        if (statut) {
          return statutProjetColor[statut]
        }
        return null
      } catch (e) {
        return null
      }
    },
    goToAnotherFicheClient(prospectId, prospectCategorie, prospectType) {
      this.$emit('show-prospect', prospectId, prospectCategorie, prospectType)
    }
  }
}
</script>
