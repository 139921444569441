<template>
  <div>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-modal.modal-center variant="primary" size="md">
      <feather-icon icon="PlusIcon" />

      <span class="align-middle">Importer votre base clients/contrats</span>
    </b-button>
    <vue-good-table>
      <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field === 'actions'">
          <span>

          </span>
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
          <b-col md="6" lg="5" xxl="3">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Affichage 1 à
              </span>
              <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})" />
              <span class="text-nowrap"> de {{ props.total }} entrées </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
    </vue-good-table>
    <b-modal size="lg" centered title="Importer base clients/contrats" id="modal-center" ref="modal-import-base-client"
      :hide-footer="hideFooter" @hide="clearDataExcel">
      <b-overlay :show="showLoading" no-wrap />
      <div class="d-flex justify-content-between mb-2">

        <div class="demo-spacing-0 " >
              <b-alert
                variant="primary"
                show
                >
                <div class="alert-body">
                  <span>Télécharger le modèle Excel recommandé pour importer votre base clients/contrats</span>
                </div>
              </b-alert>
        </div>
        <div class="mb-1 ">
                <a @click.prevent="downloadMultipFile">
                <b-button variant="primary" size="md" style="font-size:12px" >
                  <feather-icon icon="DownloadCloudIcon"  />
                  Modèle</b-button></a>
                <a href="/contratImport/Base-clients-contrats.xlsx" ref="contratAndClientImport_excel" style="display:none" download>professionl_excel</a>
               
        </div>
      </div>
      <h4 class="mb-2"> Fichier :</h4>
      <div class="d-flex bd-highlight">
        <div class="w-100 bd-highlight">
            <validation-provider #default="{ errors }" name="document">
          <b-input-group>
            <b-input-group-prepend>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon"
                :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'"
                v-b-tooltip.hover="'Charger un ficher'" @click="openFileExel">
                <b-form-file type="file" id="fileClientExel" class="d-none" v-model="fileClientExel"
                  accept=".xlsx ,.xlsm,.xlsb,.xltx" />
                <feather-icon icon="UploadCloudIcon" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input disabled  placeholder="Importer votre base clients/contrats"
              :value="fileClientExel ? fileClientExel.name : ''" :state="errors.length > 0 ? false : null" />
          </b-input-group>
        </validation-provider>
        </div>

        <div class="pl-2 flex-shrink-1 bd-highlight">
               <b-button variant="primary"  style="font-size:12px" class="text-right" @click="recordDocumentExcel">
                 Importer
              </b-button>
        </div>
    
      </div>
     <app-collapse class="mt-2">
      <app-collapse-item title="Tuto : Comment intégrer votre base clients/contrats ?" >
      <div  class="mt-1">  1/ Télécharger le modèle Excel.</div>
      <div  class="mt-1"> 2/ Compléter le fichier Excel en respectant les règles relatives aux champs obligatoires et aux valeurs par défauts décrites sur le fichier.</div>
      <div  class="mt-1"> 3/ Importer le fichier.</div>
      <div  class="mt-1"> 4/ En cas d’erreurs d’import, télécharger le fichier d’erreurs avec les ajouts en erreur, ajuster les lignes concernées et importer à nouveau.</div>
    
  
       </app-collapse-item>
        </app-collapse>
     <div class="d-flex mt-2 bd-highlight"  v-if="showResult">
        <div class="w-100 bd-highlight">
        <h5 style="font-size:12px;">
        <span style="text-align:Left-aligned">
         Résultat : <br>
          <span style="color:green;line-height:18px" class="pt-2">{{newClientWithContrat.length}} <span v-if="newClientWithContrat.length<2">client</span><span v-else>clients</span>/ {{someClientAndcontrat}} <span v-if="someClientAndcontrat<2">contrat inséré.</span><span v-else>contrats insérés.</span></span><br>
           <span style="color:red;line-height:18px" class="pt-2"> {{ failedRows.length }} <span v-if="failedRows.length<2">ligne invalide</span> <span v-else>lignes invalides</span>.</span><br>
           <span style="line-height:18px" class="pt-2" v-if="failedRows.length">  Vous pouvez télécharger les fichiers contenant les lignes non insérées pour réajustement.</span><br>
           
        </span>
            
          </h5>
        </div>
        <div class="pl-2 flex-shrink-1 bd-highlight " v-if="failedRows.length">
            <b-button variant="primary" style="font-size:11px;" class="text-right align-items-end" @click="downloadFailedRowsClientAndContrats">
               Télécharger
          </b-button>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-between  mb-2">
      </div> -->
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BSidebar,BOverlay,BAvatar,BFormRadio,VBTooltip ,BFormFile, VBToggle,BModal, BFormRadioGroup,BCardText,BCard,BFormGroup, BFormInput, BFormCheckbox, BForm, BInputGroup, BInputGroupPrepend,BFormInvalidFeedback,BFormSelect,BFormSelectOption
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, } from "vee-validate";
import { required } from "@validations";
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import { BAlert} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'


export default {
  components: {
    BButton,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    BSidebar,
    BCardText,
    BCard,
    BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    Cleave,
    BAvatar,
    BModal,
    VueGoodTable,
    BFormRadio,
    BFormFile,
    BFormRadioGroup,
    VBTooltip,
    BOverlay
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data(){
    return{
        hideFooter:false,
        fileClientExel:null,
        categorieSelected:'particulier',
        ligneNonvalid:true,
        showLoading: false,
        showResult:false,
        palceholder:'',
        failedRows :null,
        newClientWithContrat:null,
        someClientAndcontrat:null,
        fileErrorName:null,
          
      
     }
  },
  mounted() {
   this.hideFooter=true
  },
  methods: {
   downloadMultipFile(){
     this.$refs.contratAndClientImport_excel.click();
   },
       openFileExel(){
         document.getElementById('fileClientExel').click()

        },  
       clearDataExcel(){
          this.fileClientExel = null 
          this.showResult = null
        },
        recordDocumentExcel(){
              this.showLoading = true
              const formData = new FormData()
              formData.append('fileExelClientContrat', this.checkIsNull(this.fileClientExel))
              const config = { headers: { 'Content-Type': 'multipart/form-data' } }
                this.$http
                  .post('document/testImportBaseClientAndContrat',formData,config)
                  .then(res => {
                    if (res.data.success) {
                      this.failedRows=res.data.data.failedRows
                      this.newClientWithContrat = res.data.data.newClientWithContrat
                      this.someClientAndcontrat = res.data.data.countAllContratAndClient
                  
                      this.showResult = true
                      if(res.data.data[1] == 0){
                          this.$bvModal.hide('modal-center')
                          this.clearDataExcel()
                            this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Client ajoutés`,
                          icon: 'UserIcon',
                          variant: 'success',
                          text: 'Les clients ont été ajoutés avec succès.',
                    },
                  })
                  }
                  let errorName =this.fileClientExel.name
                  this.fileErrorName = errorName.substring(0,errorName.indexOf('.')-1);
                  this.$emit('fetch-contrats-list')
                  this.fileClientExel = null 
                  this.showLoading = false
                    }
                  }).catch(err => {
                     this.showLoading = false
                     console.log(err)
                     this.clearDataExcel()
                     this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                  })
                },
          downloadFailedRowsClientAndContrats(){
              this.showLoading = true
                  // this.showLoading = true
                 if(this.failedRows.length>0){
                 this.$http
                  .post('document/downloadFailedRowsClientAndContrats',{ 
                    failedRows: this.failedRows,

                     },{
                    responseType: "blob"   
                  })
                  .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `${this.fileErrorName}-errors.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.showLoading = false
                    // this.$bvModal.hide('modal-center')
                    this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Document téléchargé`,
                      icon: 'UserIcon',
                      variant: 'success',
                      text: 'Document a été bien téléchargé.',
                    },
                   })
                  })
                }   
            },
         },
             
}


</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
