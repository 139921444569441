<template>
  <b-tabs content-class="pt-2" justified>
    <b-tab :title="`Documents ${DocumentsCount > 0 ? '('+DocumentsCount+')' : ''}`">
      <b-card-text>
        <autres-document 
         :fresh="fresh"
         v-if="contratData.id"
         :fromChild="true"
         :isDocumentAdded="isDocumentAdded"
         :list-type="optionsType"
         @count-docs-refreshed="countDocsRefreshed"
         @initial-docs-refreshed="initialDocsRefreshed"
         @ajouter-document="$emit('ajouter-document', contratData.contrable.id, contratData.id, contratData.contrable.type, true)"
         :contrat-id-doc="contratData.id" />
      </b-card-text>
    </b-tab>
    <b-tab :title="`Sinistres ${SinistreCount > 0 ? '(' + SinistreCount + ')' : ''}`">
      <b-card-text>
        <listSinistre @refersh-document="freshdocumet" :contrat-data="contratData" :contart-id-type="itemChosen" :is-fiche-contrat="true" @count-sinistre-refreshed="countSinistreRefreshed"/>
      </b-card-text>
    </b-tab>
    <b-tab :title="`Réclamations ${ReclamationsCount > 0 ? '('+ReclamationsCount+')' : ''}`" v-if="can('Show complaints') || isPrincipal()">
      <b-card-text >
        <reclamation :contrat-data="contratData" :contart-id-type="itemChosen" :is-fiche-contrat="true" @count-reclamations-refreshed="countReclamationsRefreshed"/>
      </b-card-text>
    </b-tab>
    <b-tab :title="`Notes ${NotesCount > 0 ? '('+NotesCount+')' : ''}`">
      <b-card-text>
        <list-notes  :item-chosen="itemChosen" @count-notes-refreshed="countNotesRefreshed" />
      </b-card-text>
    </b-tab>
    <b-tab title="Activités" disabled>
      <b-card-text>
        <p>Activités</p>
      </b-card-text>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCardText, BButton } from 'bootstrap-vue'
import AutresDocument from '@/views/portefeuilles/contrats/AutresDocument.vue'
import documentService from '@/shared/services/documentService'
import listNotes from '@/views/projets/projet/details-fiche-projet/notes/Note.vue'
import { ref } from '@vue/composition-api'
import Reclamation from './details-fiche-projet/reclamations/Reclamation.vue'
import listSinistre from './details-fiche-projet/sinistres/Sinistre.vue'


export default {
  components: {
    BTabs,
    BTab,
    BCardText,
    BButton,
    AutresDocument,
    listNotes,
    Reclamation,
    listSinistre
  },
  props: {
    contratData: {
      type: Object,
      required: true,
    },
    isDocumentAdded: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      DocumentsCount: 0,
      ReclamationsCount: 0,
      SinistreCount: 0,
      NotesCount :0,
      fresh:0,
      famille: null,
      optionsType: [],
      itemChosen: {
        typeInterface: 'contrat',
        itemId: null,
        clientId: null,
        clientType: null
      }
    }
  },
  watch: {
    optionsFamille: {
      deep:true,
      handler(val){
          this.famille = val.find(item => item.value.id == 4).value
          this.changeListoptionType()
      },
    },
    contratData: {
      deep:true,
      handler(val) {
        if(val) {
          this.itemChosen.itemId      = val.id
          this.itemChosen.clientId    = val.contrable_id
          this.itemChosen.clientType  = val.contrable_type
        }
      }
    }
  },
  methods: {
    freshdocumet(){
      this.fresh += 1
    },
     countDocsRefreshed(countDocs){
      this.DocumentsCount=countDocs
    },
    countSinistreRefreshed(countSinistre) {
      this.SinistreCount = countSinistre
    },
     countReclamationsRefreshed(countReclamations){
      this.ReclamationsCount=countReclamations
    },
     countNotesRefreshed(countNotes){
      this.NotesCount=countNotes
    },
    initialDocsRefreshed(refreshed) {
      this.$emit('initial-docs-refreshed', refreshed)
    },
    changeListoptionType() {
      if (this.famille) {
        this.optionsType = []
        this.famille.typeDocument.forEach(el => {
          this.optionsType.push({
            value: el.id,
            text: el.type
          })
        })
      } else {
        this.optionsType = []
      }
    },
  },
  setup() {
    const optionsFamille = ref([])
    const fetchDocumentNatureWithTypes = documentService()
    fetchDocumentNatureWithTypes
      .then(res => {
      optionsFamille.value = []
        res.data.filter(function(a) {return a.id == 4;}).forEach(el => {
          optionsFamille.value.push({
            value: { id: el.id, typeDocument: el.documentype },
            text: el.libelle
          })
        })
      })
      .catch(errors => {
        console.error(errors)
        optionsFamille.value = []
      })

    return {
      fetchDocumentNatureWithTypes,
      optionsFamille

    }
  }
}
</script>
